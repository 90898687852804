<template>
  <UNotifications />
  <div
    class="logo pt-[90px] mt-5 font-['Space_Grotesk']"
    style="margin-left: 12vw; margin-right: 12vw;"
  >
    <div class="grid md:grid-cols-2 grid-rows-1 gap-10">
      <div class="row-end-1 pr-6">
        <div class="text-[5vw] md:text-[2.97vw] leading-none font-['Space_Grotesk'] font-bold">
          <slot name="header" />
        </div>
      </div>
      <div class="hidden md:row-span-2 md:block">
        <slot name="contentRight" />
      </div>
      <div class="row-start-auto pr-6">
        <slot name="contentLeft" />
      </div>
      <div class="mb-[20px] md:col-span-2 flex content-start items-baseline place-content-center">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<style>
.logo {
  background: url('~/assets/logo.svg') no-repeat;
}

body {
  height: 100px;
  background-image: url('~/assets/background-2xl-light.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>